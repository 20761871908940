<template>
  <v-card class="px-6 py-4">
    <h2>Request for Support</h2>
    <p v-if="error" class="text-center" style="color: orange">
      Please fill all the boxes!
    </p>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="formData.invoice"
          label="Invoice Number"
          :rules="stringRules"
          required
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="!editable">
        <p v-if="this.formData.branch && this.projectByIndex" class="mb-0">
          Address: {{ this.formData.branch }}
        </p>
        <p
          v-if="!this.projectByIndex && formData.invoice"
          class="mb-0"
          style="color: red"
        >
          Please provide valid Invoice Number
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="formData.mobile_number"
          label="Mobile Number"
          :rules="stringRules"
          required
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="formData.email"
          label="Email Address"
          :rules="emailRules"
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="formData.support_type"
          :items="support_type"
          label="Support Type"
          :rules="stringRules"
          required
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="formData.support_type === 'Other'">
        <v-text-field
          v-model="formData.other_type"
          label="Other Type"
          :rules="stringRules"
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="" v-if="editable">
      <v-col cols="12" sm="6" md="6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.completion_date"
              label="Completion Date"
              :rules="stringRules"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.completion_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="formData.status"
          :items="status"
          label="Status"
          required
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          v-model="formData.description"
          :rules="stringRules"
          label="Description"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="mt-6">
      <div class="d-flex justify-end">
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn color="button" dark class="ml-5" @click="submit">
          <v-progress-circular
            v-if="loading"
            class="mr-2"
            indeterminate
            size="16"
          ></v-progress-circular>
          Submit Request</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        invoice: "",
        branch: "",
        delivered_date: "",
        requested_date: "",
        completion_date: "",
        support_type: "",
        other_type: "",
        support: "",
        mobile_number: "",
        email: "",
        status: "",
        description: "",
        updated_by: "",
        requested_by: "",
      },
      branches: ["Mirpur", "Dhanmondi", "Banani"],
      support_type: ["SL-1", "SL-2", "SL-3", "Other"],
      status: ["In-progress", "In-Queue", "Completed"],
      menu2: false,
      stringRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      error: false,
      loading: false,
      projectList: [],
      projectByIndex: {},
    };
  },
  props: ["editable", "selectedItem"],
  computed: {
    todayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
  },
  methods: {
    setData() {
      this.formData = Object.assign({}, this.formData, this.selectedItem);
    },
    dayDifference(oldDate, newDate) {
      const date1 = new Date(oldDate);
      const date2 = new Date(newDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    async submit() {
      if (
        this.formData.invoice &&
        this.formData.support_type &&
        this.formData.mobile_number &&
        this.formData.email
      ) {
        this.loading = true;
        this.error = false;
        if (this.editable) {
          this.formData.updated_by = this.$store.state.user.email;
          let data = await this.$store.dispatch("update", {
            api: "support",
            data: this.formData,
            id: this.selectedItem.id,
          });
        } else {
          this.formData.requested_by = this.$store.state.user.uid;
          this.formData.requested_date = this.todayDate;
          this.formData.status = "In-Queue";
          console.log(this.formData);
          if (this.formData.support_type === "Other")
            this.formData.support = this.formData.other_type;
          else this.formData.support = this.formData.support_type;
          let data = await this.$store.dispatch("addNew", {
            api: "support",
            data: this.formData,
          });
        }
        this.loading = false;
        this.$emit("close");
      } else {
        this.error = true;
      }
    },
    async fetchProjectList() {
      try {
        let data = await this.$store.dispatch("fetchAll", "project");
        this.projectList = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.fetchProjectList();
    this.setData();
  },
  watch: {
    selectedItem() {
      this.formData = null;
      this.setData();
    },
    "formData.invoice"() {
      this.projectByIndex = this.projectList.find((p) => {
        return p.invoice === this.formData.invoice;
      });
      if (this.projectByIndex) {
        this.formData.branch = this.projectByIndex.branch;
        this.formData.delivered_date = this.projectByIndex.delivered_date;
      }
    },
  },
};
</script>

<style></style>
