<template>
  <v-card class="px-6 py-4">
    <h2>Enter Delivered Project Details</h2>
    <p v-if="error" class="text-center" style="color: orange">
      Please fill all the boxes!
    </p>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="formData.invoice"
          label="Invoice Number"
          required
          :rules="stringRules"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="formData.branch"
          label="Branch Name and/or Address"
          :rules="stringRules"
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" sm="6" md="12">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.delivered_date"
              :rules="stringRules"
              label="Delivered Date"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.delivered_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          v-model="formData.description"
          :rules="stringRules"
          label="Description"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="mt-6">
      <div class="d-flex justify-end">
        <v-btn @click="$emit('close')">Cancel</v-btn>
        <v-btn color="button" dark class="ml-5" @click="submit">
          <v-progress-circular
            v-if="loading"
            class="mr-2"
            indeterminate
            size="16"
          ></v-progress-circular>
          Submit Request</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        invoice: "",
        branch: "",
        delivered_date: "",
        description: "",
        updated_by: "",
      },
      menu2: false,
      loading: false,
      stringRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      error: false,
    };
  },
  props: ["editable", "selectedItem"],
  methods: {
    setData() {
      this.formData = Object.assign({}, this.formData, this.selectedItem);
    },
    async submit() {
      if (
        this.formData.invoice &&
        this.formData.branch &&
        this.formData.delivered_date &&
        this.formData.description
      ) {
        this.loading = true;
        this.error = false;
        this.formData.updated_by = this.$store.state.user.email;
        if (this.editable) {
          let data = await this.$store.dispatch("update", {
            api: "project",
            data: this.formData,
            id: this.selectedItem.id,
          });
        } else {
          let data = await this.$store.dispatch("addNew", {
            api: "project",
            data: this.formData,
          });
        }
        this.loading = false;
        this.$emit("close");
      } else {
        this.error = true;
      }
    },
  },
  watch: {
    selectedItem() {
      this.formData = null;
      this.setData();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style></style>
