<template>
  <v-navigation-drawer permanent expand-on-hover app :width="280">
    <v-list>
      <v-list-item class="px-2" link to="/" dense>
        <v-img src="@/assets/web_logo.png"></v-img>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
          ></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="pointer">
        <v-list-item-content>
          <v-list-item-subtitle v-if="$store.state.loggedIn">{{
            $store.state.user._delegate.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon color="button">mdi-book-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Support</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        to="/project"
        v-if="$store.state.isAdmin || $store.state.isEmployee"
      >
        <v-list-item-icon>
          <v-icon color="button">mdi-checkbox-multiple-marked</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Project</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon color="button">mdi-comment-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>FAQ</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item link to="#">
        <v-list-item-icon>
          <v-icon color="button">mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon color="button">mdi-open-in-new</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Log Out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("signout");
        this.$router.push({ name: "Login" });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.pointer
  cursor: pointer
</style>
