import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyAn2IFD_47mcV67gOJJVco8xcLwKOkzV2s",
  authDomain: "wavesine-support.firebaseapp.com",
  projectId: "wavesine-support",
  storageBucket: "wavesine-support.appspot.com",
  messagingSenderId: "230924336336",
  appId: "1:230924336336:web:5b12943d287a4c52f5f2d5"
};
firebase.initializeApp(firebaseConfig)

let app

firebase.auth().onAuthStateChanged(user => {
  if(!app){
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})


