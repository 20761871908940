<template>
  <div>
    <div class="d-flex justify-end mt-5">
      <v-btn class="mr-4" small color="button" dark @click="addItem">
        Add New Project
      </v-btn>
      <v-btn class="mr-4" small> Import </v-btn>
      <v-btn small> Export </v-btn>
    </div>
    <v-row class="d-md-flex justify-md-space-between mb-4 mt-6">
      <h3 class="col-6">Delivered Projects List</h3>
      <v-text-field
        class="table-search-field col-6 ml-auto"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        hide-details
        dense
        color="button"
      />
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="projectList"
      class="elevation-1"
      :search="search"
      :loading="fetching"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="id"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          {{ item.description }}
        </td>
      </template>
      <template v-slot:item.action="{ item }" v-if="$store.state.isAdmin">
        <v-progress-circular
          v-if="submitting"
          class="mr-2"
          indeterminate
          color=""
          size="13"
          width="1.5"
        />
        <span v-else>
          <v-btn
            @click="editItem(item)"
            fab
            x-small
            class="my-1 mr-3"
            color="button"
            dark
          >
            <v-icon size="18" class="pointer">mdi-lead-pencil</v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="800">
      <project-entry
        :editable="editable"
        :selectedItem="selectedItem"
        @close="callFunc"
      />
    </v-dialog>
  </div>
</template>

<script>
import ProjectEntry from "@/components/ProjectEntry";
export default {
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Invoice Number",
          align: "center",
          sortable: false,
          value: "invoice",
        },
        {
          text: "Branch and/or Address",
          align: "center",
          sortable: false,
          value: "branch",
        },
        {
          text: "Delivered Date",
          align: "center",
          sortable: false,
          value: "delivered_date",
        },
        {
          text: "Updated By",
          align: "center",
          sortable: false,
          value: "updated_by",
        },
        { text: "", align: "center", sortable: false, value: "action" },
        { text: "", value: "data-table-expand" },
      ],
      projectList: [],
      search: "",
      submitting: false,
      fetching: false,
      dialog: false,
      editable: false,
      selectedItem: null,
    };
  },
  components: {
    ProjectEntry,
  },
  methods: {
    addItem() {
      this.selectedItem = null;
      this.editable = false;
      this.dialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.editable = true;
      this.dialog = true;
    },
    async fetchData() {
      this.fetching = true;
      try {
        let data = await this.$store.dispatch("fetchAll", "project");
        this.projectList = data;
      } catch (err) {
        console.log(err);
      }
      this.fetching = false;
    },
    callFunc() {
      this.dialog = false;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="sass" scoped>
::v-deep .table-search-field
  width: 10%
  max-width: 300px
.pop-dialog
  z-index: 999999
</style>
