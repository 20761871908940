<template>
  <div>
    <support />
  </div>
</template>

<script>
import Support from "@/components/Support";
export default {
  components: {
    Support,
  },
};
</script>

<style></style>
