<template>
  <div>
    <h1 class="text-center mt-16">Sign Up</h1>
    <div class="mt-5">
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="email"
            label="Email"
            required
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="password"
            label="Password"
            required
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-btn @click="register" class="">Register</v-btn>
        </v-col>
      </v-row>
    </div>
    <p
      class="mb-0 text-center mt-5"
      v-if="$store.state.error_message"
      style="color: orange"
    >
      {{ $store.state.error_message }}
    </p>
    <p class="my-10 text-center">
      Already have an account?
      <router-link to="/auth/login">Login</router-link>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      err: "",
    };
  },
  methods: {
    async register() {
      try {
        await this.$store.dispatch("signup", {
          email: this.email,
          password: this.password,
        });
        this.$router.push({ name: "Support" });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
button
  margin-top: 20px
  width: 100% !important
  height: 46px !important
</style>
