<template>
  <div>
    <div class="d-flex justify-end mt-5">
      <v-btn class="mr-4" small color="button" dark @click="addItem">
        Request for Support
      </v-btn>
      <v-btn class="mr-4" small> Import </v-btn>
      <v-btn small> Export </v-btn>
    </div>
    <v-row class="d-md-flex justify-md-space-between mb-4 mt-6">
      <h3 class="col-6">Support List</h3>
      <v-text-field
        class="table-search-field col-6 ml-auto"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        hide-details
        dense
        color="button"
      />
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="filteredSupportList"
      class="elevation-1"
      :search="search"
      :loading="fetching"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="id"
      show-expand
    >
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template
        v-slot:item.warrenty_remains="{ item }"
        v-if="$store.state.isAdmin || $store.state.isEmployee"
      >
        {{ 730 - dayDifference(item.delivered_date, todayDate) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          {{ item.description }}
        </td>
      </template>
      <template
        v-slot:item.action="{ item }"
        v-if="$store.state.isAdmin || $store.state.isEmployee"
      >
        <v-progress-circular
          v-if="submitting"
          class="mr-2"
          indeterminate
          color=""
          size="13"
          width="1.5"
        />
        <span v-else>
          <v-btn
            @click="editItem(item)"
            fab
            x-small
            class="my-1 mr-3"
            color="button"
            dark
          >
            <v-icon size="18" class="pointer">mdi-lead-pencil</v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="800">
      <entry-card
        :editable="editable"
        :selectedItem="selectedItem"
        @close="callFunc"
      />
    </v-dialog>
  </div>
</template>

<script>
import EntryCard from "@/components/EntryCard";
export default {
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Invoice Number",
          align: "center",
          sortable: false,
          value: "invoice",
        },
        {
          text: "Branch and/or Address",
          align: "center",
          sortable: false,
          value: "branch",
        },
        {
          text: "Support Type",
          align: "center",
          sortable: false,
          value: "support",
        },
        {
          text: "Warrenty (Remaining Days)",
          align: "center",
          sortable: false,
          value: "warrenty_remains",
        },
        {
          text: "Mobile",
          align: "center",
          sortable: false,
          value: "mobile_number",
        },
        {
          text: "Email",
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: "Requested Date",
          align: "center",
          sortable: false,
          value: "requested_date",
        },
        {
          text: "Completion Date",
          align: "center",
          sortable: false,
          value: "completion_date",
        },
        {
          text: "Updated By",
          align: "center",
          sortable: false,
          value: "updated_by",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        { text: "", align: "center", sortable: false, value: "action" },
        { text: "", value: "data-table-expand" },
      ],
      supportList: [],
      search: "",
      submitting: false,
      fetching: false,
      dialog: false,
      editable: false,
      selectedItem: null,
    };
  },
  components: {
    EntryCard,
  },
  computed: {
    todayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    filteredSupportList() {
      if (this.$store.state.isAdmin || this.$store.state.isEmployee)
        return this.supportList;
      return this.supportList.filter(
        (s) => s.requested_by === this.$store.state.user.uid
      );
    },
  },
  methods: {
    dayDifference(oldDate, newDate) {
      const date1 = new Date(oldDate);
      const date2 = new Date(newDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    getStatusColor(support_type) {
      if (support_type == "In-progress") return "orange";
      else if (support_type == "In-Queue") return "red";
      else return "green";
    },
    addItem() {
      this.selectedItem = null;
      this.editable = false;
      this.dialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.editable = true;
      this.dialog = true;
    },
    async fetchData() {
      this.fetching = true;
      try {
        let data = await this.$store.dispatch("fetchAll", "support");
        this.supportList = data;
      } catch (err) {
        console.log(err);
      }
      this.fetching = false;
    },
    callFunc() {
      this.dialog = false;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="sass" scoped>
::v-deep .table-search-field
  width: 10%
  max-width: 300px
.pop-dialog
  z-index: 999999
</style>
