import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/compat/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error_message : '',
    loggedIn : false,
    user: {},
    adminPanel: [
      {
        email: 'kazisazzadhassan@gmail.com',
        admin: true,
        employee: false
      },
      {
        email: 'wavesine@wavesine.com',
        admin: true,
        employee: false
      },
    ],
    isAdmin: false,
    isEmployee: false
  },
  mutations: {
  },
  actions: {
    // data-management
    async addNew({ commit,state  }, payload) {
      try{
        await firebase.firestore().collection(payload.api).add(payload.data).then((doc) =>{
          return doc
        })
      }catch(err) {
        console.log(err);
        state.error_message = err.message
      }
    },
    async fetchSingle({ commit,state  }, payload) {
      try{
        await firebase.firestore().collection(payload.api).doc(payload.id).get().then((doc) => {
          console.log(doc);
        })
      }catch(err) {
        console.log(err);
        state.error_message = err.message
      }
    },
    async delete({ commit,state  }, payload) {
      try{
        await firebase.firestore().collection(payload.api).doc(payload.id).delete().then((doc) => {
          console.log(doc);
        })
      }catch(err) {
        console.log(err);
        state.error_message = err.message
      }
    },
    async update({ commit,state  }, payload) {
      try{
        await firebase.firestore().collection(payload.api).doc(payload.id).set(payload.data).then((doc) => {
          console.log(doc);
        })
      }catch(err) {
        console.log(err);
        state.error_message = err.message
      }
    },
    async fetchAll({ commit,state  }, payload) {
      try{
        const snapshot = await firebase.firestore().collection(payload).get()
        return snapshot.docs.map(doc => {
          let data = doc.data()
          data.id = doc.id
          return data
        });
      }catch(err) {
        console.log(err);
        state.error_message = err.message
      }
    },

    // Authentication 
    async login({ commit,state, dispatch }, payload) {
      await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then(
        user => {
          console.log(user.user._delegate.uid);
          dispatch('authStatus')
        },
        err => {
          console.log(err);
          state.error_message = err.message
        }
      )
    },
    async signup({ commit,state,dispatch }, payload) {
      await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password).then(
        user => {
          console.log(user.users);
          dispatch('authStatus')
        },
        err => {
          console.log(err);
          state.error_message = err.message
        }
      )
    },

    async signout() {
      try{
        let data = await firebase.auth().signOut()
        console.log(data);
      }catch(err){
        console.log(err);
      }
    },

    async authStatus({ commit,state }) {
      try{
        await firebase.auth().onAuthStateChanged(user => {
          state.loggedIn = !!user
          state.user = user
          console.log(user, 'user');
          for(let i=0; i<state.adminPanel.length; i++){
            if(state.adminPanel[i].email === user._delegate.email){
              if(state.adminPanel[i].admin){
                state.isAdmin = true
              }else{
                state.isEmployee = true
              }
            }
          }
        })
      }catch(err) {
        console.log(err);
      }
    }
  },
  modules: {
  }
})
