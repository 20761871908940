<template>
  <div>
    <project />
  </div>
</template>

<script>
import Project from "@/components/Project";
export default {
  components: {
    Project,
  },
};
</script>

<style></style>
