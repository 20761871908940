import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue'
import AuthLayout from '@/layout/AuthLayout.vue'
import Dashboard from '@/views/Dashboard.vue'
import Support from '@/views/Support.vue'
import Project from '@/views/Project.vue'

import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'

import firebase from 'firebase/compat/app'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AppLayout',
    component: AppLayout,
    meta: {requiresAuth:true},
    children: [
      {
        path: '',
        name: 'Support',
        component: Support
      },
      {
        path: '/project',
        name: 'Project',
        component: Project
      },
    ]
  },
  {
    path: '/auth/',
    name: 'AuthLayout',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  if(requiresAuth && !isAuthenticated){
    next('/auth/login')
  }else{
    next()
  }
})

export default router
