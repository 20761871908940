<template>
  <div>
    <side-bar />
    <v-container>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  components: {
    SideBar,
  },
  created() {
    this.$store.dispatch("authStatus");
  },
};
</script>

<style></style>
