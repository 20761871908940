var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end mt-5"},[_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","color":"button","dark":""},on:{"click":_vm.addItem}},[_vm._v(" Request for Support ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v(" Import ")]),_c('v-btn',{attrs:{"small":""}},[_vm._v(" Export ")])],1),_c('v-row',{staticClass:"d-md-flex justify-md-space-between mb-4 mt-6"},[_c('h3',{staticClass:"col-6"},[_vm._v("Support List")]),_c('v-text-field',{staticClass:"table-search-field col-6 ml-auto",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","dense":"","color":"button"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.filteredSupportList,"search":_vm.search,"loading":_vm.fetching,"expanded":_vm.expanded,"single-expand":true,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},(_vm.$store.state.isAdmin || _vm.$store.state.isEmployee)?{key:"item.warrenty_remains",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(730 - _vm.dayDifference(item.delivered_date, _vm.todayDate))+" ")]}}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},(_vm.$store.state.isAdmin || _vm.$store.state.isEmployee)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.submitting)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"","size":"13","width":"1.5"}}):_c('span',[_c('v-btn',{staticClass:"my-1 mr-3",attrs:{"fab":"","x-small":"","color":"button","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"pointer",attrs:{"size":"18"}},[_vm._v("mdi-lead-pencil")])],1)],1)]}}:null],null,true)}),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('entry-card',{attrs:{"editable":_vm.editable,"selectedItem":_vm.selectedItem},on:{"close":_vm.callFunc}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }